<template>
    <div class="monitor-index">
        <div class="container-header" v-if="!dangerid">
            <div class="top-actions-group">
                <div class="action-left">
                    <el-form class="geology-form">
                        <el-select v-model="listfilter.dangerid" filterable remote reserve-keyword default-first-option
                            clearable placeholder="选择监测点" size="medium" :remote-method="getDangerList"
                            :loading="getDangerLoading" @change="onDangerChange">
                            <el-option v-for="item in dangerSelect" :key="item.Id" :label="item.DangerName"
                                :value="item.Id">
                            </el-option>
                        </el-select>
                        <el-select style="margin-left: 10px;" v-model="listfilter.monitorid" filterable remote
                            reserve-keyword default-first-option clearable placeholder="请选择监测点位" size="medium"
                            :remote-method="getMonitorList" :loading="getMonitorLoading" @change="onSearch">
                            <el-option v-for="item in monitorSelect" :key="item.Id" :label="item.MonitorName"
                                :value="item.Id">
                            </el-option>
                        </el-select>
                        <el-select v-model="listfilter.DeviceType" size="medium" style="margin-left: 10px; width: 140px"
                            placeholder="设备类型" clearable @change="onSearch">
                            <el-option v-for="item in DeviceTypeData" :key="item.Id" :label="item.ItemName"
                                :value="item.ItemValue">
                            </el-option>
                        </el-select>
                        <el-select v-model="listfilter.state" size="medium" style="margin-left: 10px; width: 120px"
                            placeholder="设备状态" clearable @change="onSearch">
                            <el-option label="在线" value="1"></el-option>
                            <el-option label="离线" value="0"></el-option>
                            <el-option label="维修" value="2"></el-option>
                            <el-option label="拆除" value="3"></el-option>
                        </el-select>
                        <el-select v-model="listfilter.WaringLevel" size="medium"
                            style="margin-left: 10px; width: 120px" placeholder="预警等级" clearable @change="onSearch">
                            <el-option label="未预警" :value="0"></el-option>
                            <el-option label="蓝色" :value="1"></el-option>
                            <el-option label="黄色" :value="2"></el-option>
                            <el-option label="橙色" :value="3"></el-option>
                            <el-option label="红色" :value="4"></el-option>
                        </el-select>
                        <el-date-picker v-model="filterDaterange" type="daterange" range-separator="-"
                            start-placeholder="开始日期" end-placeholder="结束日期" size="medium"
                            style="width: 240px; margin-left: 10px" value-format="yyyy-MM-dd" @change="dateChange">
                        </el-date-picker>

                        <el-input placeholder="设备名称、编码" style="width: 240px; margin-left: 10px" size="medium"
                            class="form-input-group" v-model="listfilter.fname" @keyup.enter.native="onSearch">
                            <el-button slot="append" type="primary" icon="el-icon-search"
                                @click="onSearch">查询</el-button>
                        </el-input>
                    </el-form>
                </div>
                <div class="action-right">
                    <router-link to="/device/add">
                        <el-button type="primary" icon="el-icon-circle-plus-outline" size="medium">新增</el-button>
                    </router-link>
                </div>
            </div>
        </div>

        <el-table class="list-table list-table-border" ref="multipleTable" :data="listData" tooltip-effect="dark"
            style="width: 100%" :stripe="true" border v-loading="tableLoading"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column label="监测点位名称" :show-overflow-tooltip="true" prop="MonitorName"></el-table-column>
            <el-table-column label="监测点名称" :show-overflow-tooltip="true" prop="DangerName"></el-table-column>
            <el-table-column label="设备名称" :show-overflow-tooltip="true" prop="DeviceName"></el-table-column>
            <el-table-column label="设备类型" prop="DeviceType"></el-table-column>
            <el-table-column label="设备位置" :show-overflow-tooltip="true" prop="Address"></el-table-column>
            <el-table-column label="状态" width="80" prop="IsOn">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.IsOn == 1" effect="dark" size="small">在线</el-tag>
                    <el-tag v-if="scope.row.IsOn == 0" type="danger" effect="dark" size="small">离线</el-tag>
                    <el-tag v-if="scope.row.IsOn == 2" type="success" effect="dark" size="small">维修</el-tag>
                    <el-tag v-if="scope.row.IsOn == 3" type="info" effect="dark" size="small">拆除</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="预警" prop="WaringLevel" align="center">
                <template slot-scope="scope">
                    <el-link @click="onListActions({ action: 'yj', item: scope.row })">
                        <el-tag v-if="scope.row.WaringLevel == 1" color="blue" style="border:none;" effect="dark"
                            size="small">蓝色预警</el-tag>
                        <el-tag v-if="scope.row.WaringLevel == 2" color="yellow" style="color:#000;border:none;"
                            effect="dark" size="small">黄色预警</el-tag>
                        <el-tag v-if="scope.row.WaringLevel == 3" color="orange" style="border:none;" effect="dark"
                            size="small">橙色预警</el-tag>
                        <el-tag v-if="scope.row.WaringLevel == 4" type="danger" style="border:none;" effect="dark"
                            size="small">红色预警</el-tag>
                    </el-link>
                </template>
            </el-table-column>
            <el-table-column label="最新时间" prop="LastUpdateTime">
                <template slot-scope="scope">
                    {{ scope.row.LastUpdateTime | datetime }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="240">
                <template slot-scope="scope">
                    <router-link :to="'/device/detail?id=' + scope.row.Id">
                        <el-button type="success" icon="el-icon-view" size="mini">详情</el-button>
                    </router-link>
                    <router-link :to="'/device/edit?id=' + scope.row.Id">
                        <el-button type="success" icon="el-icon-edit" size="mini"
                            style="margin-left: 10px">修改</el-button>
                    </router-link>
                    <el-dropdown trigger="click" @command="onListActions">
                        <el-button type="primary" size="mini" style="margin-left: 10px">
                            更多<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="{ action: 'del', item: scope.row }">删除</el-dropdown-item>
                            <el-dropdown-item :command="{ action: 'qx', item: scope.row }">曲线</el-dropdown-item>
                            <el-dropdown-item :command="{ action: 'yj', item: scope.row }">预警信息</el-dropdown-item>
                            <el-dropdown-item :command="{ action: 'yjjs', item: scope.row }">预警接收</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>

        <pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" class="list-pagination"
            :page-size="page.pagesize" hide-on-single-page :total="page.total" :current-page.sync="page.pageindex"
            @current-change="onPageChange" />
    </div>
</template>
<script>
import { getDangerList, getMonitorList } from "@/api/base";
import { getDeviceList, delDevice } from "@/api/device";
export default {
    name: "DeviceList",
    data() {
        return {
            DeviceTypeData: [],

            listData: [],
            tableLoading: false,
            filterDaterange: "",

            getDangerLoading: false,
            dangerSelect: [],
            getMonitorLoading: false,
            monitorSelect: [],

            listfilter: {
                dangerid: "",
                monitorid: "",
                DeviceType: "",
                state: "",
                fname: "",
                fstarttime: "",
                fendtime: "",
                WaringLevel: "",
            },
            page: {
                pagesize: 10,
                pageindex: 1,
                total: 0,
            },
            dialogDetailVisible: false,
            activeMonitorDetail: "",
        };
    },
    props: {
        dangerid: [String, Number],
    },
    components: {},
    methods: {
        getDangerList(value) {
            this.getDangerLoading = true;
            getDangerList(1, 100, {
                fname: value,
            }).then((resdata) => {
                this.getDangerLoading = false;
                if (resdata.code == 0) {
                    this.dangerSelect = resdata.data.data;
                }
            });
        },
        onDangerChange(value) {
            this.getMonitorList();
            this.onSearch();
            this.listfilter.monitorid = '';
        },
        getMonitorList(value) {
            this.getMonitorLoading = true;
            getMonitorList(1, 100, {
                fname: value,
                dangerid: this.listfilter.dangerid,
            }).then((resdata) => {
                this.getMonitorLoading = false;
                if (resdata.code == 0) {
                    this.monitorSelect = resdata.data.data;
                }
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        dateChange(value) {
            if (value) {
                var newdate = [];
                for (
                    let index = 0;
                    index < this.filterDaterange.length;
                    index++
                ) {
                    const element = this.filterDaterange[index];
                    newdate.push(element);
                }
                this.listfilter.fstarttime = newdate[0];
                this.listfilter.fendtime = newdate[1];
                this.getListData();
            } else {
                this.listfilter.fstarttime = "";
                this.listfilter.fendtime = "";
                this.getListData();
            }
        },
        onSearch() {
            this.page.pageindex = 1;
            this.getListData();
        },
        onPageChange(pageindex) {
            this.page.pageindex = pageindex;
            this.getListData();
        },
        getListData() {
            this.tableLoading = true;
            let params = { ...this.listfilter };
            if (!params.WaringLevel && params.WaringLevel !== 0) {
                params.WaringLevel = -1;
            }
            getDeviceList(
                this.page.pageindex,
                this.page.pagesize,
                params
            ).then((resdata) => {
                this.tableLoading = false;
                if (resdata.code == 0) {
                    this.listData = resdata.data.data;
                    this.page.total = resdata.data.count * 1;
                } else {
                    this.$message({
                        type: "error",
                        message: resdata.msg,
                    });
                }
            });
        },
        onListActions(data) {
            switch (data.action) {
                case "detail":
                    this.$router.push("/device/detail?id=" + data.item.Id);
                    break;
                case "del":
                    this.onListDel(data.item);
                    break;
                case "qx":
                    this.$router.push("/device/detail?id=" + data.item.Id);
                    break;
                case "yj":
                    this.$router.push(
                        "/device/detail?tab=tabWarning&id=" + data.item.Id
                    );
                    break;
                case "yjjs":
                    this.$router.push("/warning/push?id=" + data.item.Id);
                    break;
                default:
                    break;
            }
        },
        onListDel(item) {
            this.$confirm("确定要删除选择的数据吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delDevice(item.Id)
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message.success("删除成功!");
                                this.getListData();
                            } else {
                                this.$message.error("删除失败!" + res.msg);
                            }
                        })
                        .catch((errmsg) => {
                            this.$message.error(errmsg);
                        });
                })
                .catch(() => { });
        },
        onShowDetail(item) {
            this.activeMonitorDetail = item;
            this.dialogDetailVisible = true;
        },
    },
    created() {
        if (this.dangerid) {
            this.listfilter.dangerid = this.dangerid * 1;
        }
        this.$store.dispatch("dictionary/getDeviceType").then((data) => {
            this.DeviceTypeData = data;
        });
        if (this.$route.query.dangerid) {
            this.listfilter.dangerid = this.$route.query.dangerid * 1;
        }
        this.getDangerList();
        this.getListData();

    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('global/addInclude', 'DeviceList');
        })
    },
    beforeRouteLeave(to, from, next) {
        if (to.path != '/device/detail' && to.path != '/device/edit') {
            this.$store.dispatch('global/removeInclude', 'DeviceList');
        }
        next();
    },
    watch: {
        dangerid: () => {
            this.listfilter.dangerid = this.dangerid * 1;
        }
    },
};
</script>